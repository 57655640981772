<template>
  <div>
    <div class="head">
      <div class="head-title">联系小鸡</div>
    </div>
    <div id="container">
      <div v-if="$store.getters.getIsPhone" class="box">
        <div class="box-card">
          <div class="card-item card-br">
            <div class="item-title">用户反馈</div>
            <div class="item-msg">
              联系方式：0551-65583732
              <br />
              公司地址：安徽合肥市包河区万达广场6号楼33层
              <br />
              公司邮箱：customer@32ka.com
            </div>
          </div>
          <div class="card-item card-br">
            <div class="item-title">商务合作</div>
            <div class="item-msg">
              联系方式：0551-65583732
              <br />
              公司地址：安徽合肥市包河区万达广场6号楼33层
              <br />
              企业邮箱：business@32ka.com
            </div>
          </div>
          <div class="card-item">
            <div class="item-title">招贤纳士</div>
            <div class="item-msg">
              联系方式：0551-65558888
              <br />
              公司地址：安徽合肥市包河区万达广场6号楼33层
              <br />
              公司邮箱：hr@32ka.com
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!$store.getters.getIsPhone" class="my-box">
      <div class="my-box_card">
        <div class="item-title">用户反馈</div>
        <div class="item-msg">
          联系方式：0551-65583732
          <br />
          公司地址：安徽合肥市包河区万达广场6号楼33层
          <br />
          公司邮箱：customer@32ka.com
        </div>
        <div class="item-title item-line">商务合作</div>
        <div class="item-msg">
          联系方式：0551-65583732
          <br />
          公司地址：安徽合肥市包河区万达广场6号楼33层
          <br />
          企业邮箱：business@32ka.com
        </div>
        <div class="item-title item-line">招贤纳士</div>
        <div class="item-msg">
          联系方式：0551-65558888
          <br />
          公司地址：安徽合肥市包河区万达广场6号楼33层
          <br />
          公司邮箱：hr@32ka.com
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {}
  },

  mounted() {
    window._AMapSecurityConfig = {
      securityJsCode: "4492504b5a5373cb3089994497515b80",
    }
    AMapLoader.load({
      key: "99b4634b2b46c3977011327e0230fe05", //申请好的Web端开发者 Key，调用 load 时必填
      version: "2.0", //指定要加载的 JS API 的版本，缺省时默认为 1.4.15
      plugins: ["AMap.Scale", "AMap.ToolBar", "AMap.Geolocation"],
    })
      .then((AMap) => {
        const map = new AMap.Map("container", {
          zoom: 13,
          center: [117.302741, 31.857032], //地图中心点
          dragEnable: true,
          scrollWheel: false,
          resizeEnable: true,
        })
        map.addControl(new AMap.Scale())
        const marker = new AMap.Marker({
          position: [117.302741, 31.857032], //位置
        })
        map.add(marker) //添加到地图
        var toolbar = new AMap.ToolBar() //缩放工具条实例化
        map.addControl(toolbar)

        //信息窗体的内容
        var content = ["<div><b>充充数字科技有限公司</b>", "地址 : 安徽省合肥市包河万达广场6号楼3301</div>"]

        //创建 infoWindow 实例
        var infoWindow = new AMap.InfoWindow({
          content: content.join("<br>"), //传入字符串拼接的 DOM 元素
          anchor: "'bottom-center",
        })

        //打开信息窗体
        infoWindow.open(map, map.getCenter()) //map 为当前地图的实例，map.getCenter() 用于获取地图中心点坐标。
      })
      .catch((e) => {
        console.error(e) //加载错误提示
      })
  },
}
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 21vw;
  background: #fbd92a url(../assets/contact/contact_bg1.png) no-repeat center top;
  color: #fff;
  padding-top: 6vw;
  box-sizing: border-box;
  background-size: cover;
  .head-title {
    width: 100%;
    text-align: center;
    font-size: 4vw;
    color: #000;
  }
}
#container {
  width: 100%;
  height: 700px;
  position: relative;
  .box {
    position: absolute;
    bottom: 0;
    width: 1200px;
    height: 200px;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    .box-card {
      max-width: 1200px;
      margin: 0 auto;
      padding: 24px;
      box-sizing: border-box;
      background-color: #fff;
      display: flex;
      .card-item {
        width: calc(100% / 3);
        margin-left: 16px;
        .item-title {
          width: 100%;
          font-size: 22px;
          color: #000;
        }
        .item-msg {
          line-height: 33px;
          font-size: 16px;
          margin-top: 5px;
          color: #666;
        }
      }
      .card-br {
        border-right: 1px dotted #e4e3e3;
      }
    }
  }
}
.my-box {
  width: 100%;
  .my-box_card {
    padding: 24px;
    box-sizing: border-box;
    background-color: #fff;
    .item-title {
      width: 100%;
      font-size: 5.2vw;
    }
    .item-msg {
      width: 100%;
      font-size: 3.6vw;
      line-height: 6.6vw;
      color: #666;
      padding-bottom: 20px;
    }
    .item-line {
      padding-top: 20px;
      border-top: 1px solid #e4e3e3;
    }
  }
}
</style>
